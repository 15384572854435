<!-- 首页小分类商品模块 -->
<template>
  <div v-for="bigItem in goodsList" :key="bigItem.id">
    <div
      class="shangPinFenLei"
      :style="'background-color:' + bigItem.default_back_color"
    >
      <span>{{ bigItem.translate_name }} </span>
      <img
        @click="
          $fun.statistics_page_sum('分类关键词搜索数量统计');
          $fun.routerToPage(
            '/CommoditySearch?keyword=' +
              bigItem.translate_name +
              '&CN=' +
              $fun.toCode(bigItem.chinese_name)
          );
        "
        :src="require('@/assets/icon/shouyeright.png')"
        alt=""
      />
    </div>
    <div class="goodsList">
      <div
        class="listOpt"
        v-for="goodsItem in bigItem.goods"
        :key="goodsItem.sales"
        @click="
          $fun.toCommodityDetails(
            goodsItem.goods_id,
            goodsItem.goods_type,
            false,
            'classify'
          )
        "
      >
        <myImg class="goodsImg" :src="goodsItem.goods_pic" lazyload />
        <!-- <img class="goodsImg" v-lazy="goodsItem.goods_pic" /> -->
        <p class="goodsTitle">
          <span class="icon1688" v-if="goodsItem.goods_type == '1688'">
            <img :src="require('@/assets/icon/icon1688.png')" alt="" /> </span
          >{{ goodsItem.goods_title }}
        </p>
        <p class="goodsPrice">
          <span class="en">
            {{ $fun.RMBNumSegmentation(goodsItem.goods_price) }}元
          </span>
          <span class="cn">
            ({{
              $fun.JPYNumSegmentation(
                $fun.roundNumber(
                  goodsItem.goods_price * $store.state.exchangeRate
                )
              )
            }}円)</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goodsList: [],
    };
  },
  components: {},
  computed: {},
  created() {
    this.autoGoods();
  },
  methods: {
    // 获取推荐商品数据
    autoGoods() {
      this.$api.autoGoods().then((res) => {
        this.goodsList = [];
        let alldata = res.data;
        // 将所有二级分类里的子数据全部放到一个数组里返回
        alldata.forEach((item, index) => {
          this.goodsList.push({
            translate_name: item.translate_name,
            default_back_color: item.default_back_color,
            chinese_name: item.chinese_name,
            goods: (() => {
              let goodsList = [];
              item.childs.forEach((childsItem) => {
                // 2023/2/23 因为随机取对浏览器加载速度不友好(图片需要一直加载,固定的只要加载一次就好了)并且客户想要返回首页购买商品时会找不到,所以这里取每个耳机分类前两个商品,不随机取
                goodsList.push(...childsItem.home_goods.slice(1, 3));
              });
              return goodsList;
            })(),
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.shangPinFenLei {
  display: flex;
  justify-content: space-between;
  height: 200px;
  padding: 35px 30px;
  background: #4671f3;
  margin-bottom: -100px;

  span {
    height: 34px;
    font-size: 34px;
    font-weight: 600;
    color: #ffffff;
  }

  img {
    width: 14px;
    height: 24px;
  }
}

.goodsList {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 24px;
  padding: 0 20px;

  .listOpt {
    flex: 0 0 270px;
    margin-right: 15px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    &:last-child {
      margin-right: 0;
    }

    .goodsImg {
      width: 230px;
      height: 230px;
      margin-bottom: 15px;
    }
    .goodsTitle {
      width: 100%;
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 400;
      color: #212121;
      line-height: 26px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      .icon1688 {
        width: 61px;
        height: 23px;
        background: #ff4000;
        border-radius: 2px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        img {
          width: 42px;
        }
      }
    }
    .goodsPrice {
      font-size: 28px;
      font-weight: 600;
      color: #b4272b;
      line-height: 1;
      .en {
        font-size: 28px;
      }
      .cn {
        font-size: 24px;
      }
    }
  }
}
</style>
