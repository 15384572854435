<!-- 首页推荐商品模块 -->
<template>
  <div class="JianYi">
    <span>{{ $fanyi("为你推荐") }} </span>
    <img :src="require('@/assets/homePage/youJianTou.png')" alt="" />
  </div>
  <div class="goodsList">
    <div
      class="listOpt"
      v-for="goodsItem in goodsList"
      @click="$fun.toCommodityDetails(goodsItem.goods_id, goodsItem.goods_type)"
      :key="goodsItem.sales"
    >
      <van-image lazy-load class="goodsImg" :src="goodsItem.goods_pic">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <p class="goodsTitle" v-if="goodsItem.goods_title">
        <span class="icon1688" v-if="goodsItem.goods_type == '1688'">
          <img :src="require('@/assets/icon/icon1688.png')" alt="" />
        </span>
        {{ goodsItem.goods_title }}
      </p>
      <p class="goodsPrice" v-if="goodsItem.goods_price">
        <span class="en"> {{ goodsItem.goods_price }}{{ $fanyi("元") }} </span>
        <span class="cn"
          >({{ (goodsItem.goods_price * $store.state.exchangeRate).toFixed(0)
          }}{{ $fanyi("円") }})</span
        >
      </p>
      <van-skeleton v-else :row="2" />
    </div>
  </div>
  <div class="coBranding">
    <img :src="require('@/assets/icon/app1688row.png')" alt="" />
  </div>
</template>
<script>
import { Skeleton } from "vant";
export default {
  data() {
    return {
      goodsList: [{}, {}, {}, {}, {}],
      autoplay: true,
      translation_name: "",
      chinese_name: "",
      [Skeleton.name]: Skeleton,
    };
  },
  components: {},
  computed: {},
  created() {
    this.hotGoods();
  },
  methods: {
    // 获取推荐商品数据
    hotGoods() {
      this.$api
        .hotGoods({
          only_get_goods: 1,
        })
        .then((res) => {
          this.goodsList = res.data.splice(0, 10);
          // this.chinese_name = res.data.chinese_name;
          // this.translation_name = res.data.translation_name;
        });
      // this.$api.hotGoods().then((res) => {
      //   this.goodsList = res.data.result;
      //   this.chinese_name = res.data.chinese_name;
      //   this.translation_name = res.data.translation_name;
      // });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.JianYi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 30px 24px;
  span {
    font-size: 34px;
    font-weight: 600;
    color: #272727;
    line-height: 34px;
  }
  img {
    width: 14px;
    height: 24px;
  }
}
.goodsList {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 30px;
  margin-bottom: 25px;
  .listOpt {
    flex: 0 0 270px;
    margin-right: 15px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    &:last-child {
      margin-right: 0;
    }

    .goodsImg {
      width: 230px;
      height: 230px;
      margin-bottom: 15px;
    }
    .goodsTitle {
      width: 100%;
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 400;
      color: #212121;
      line-height: 26px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      .icon1688 {
        width: 61px;
        height: 23px;
        background: #ff4000;
        border-radius: 2px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        img {
          width: 42px;
        }
      }
    }
    .goodsPrice {
      font-size: 28px;
      font-weight: 600;
      color: #b4272b;
      line-height: 1;
      .en {
        font-size: 28px;
      }
      .cn {
        font-size: 24px;
      }
    }
  }
}
.coBranding {
  margin: 25px 0;
  text-align: center;
  img {
    width: 690px;
  }
}
.van-skeleton {
  padding: 0;
}
:deep().van-skeleton__content {
  padding: 0;
  .van-skeleton__row {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
