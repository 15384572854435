<!-- 首页猜你喜欢组件 -->
<template>
  <div class="modelPage">
    <div class="styckiBox">
      <div class="typeList" ref="MainHeader">
        <span
          v-for="(item, index) in typeList"
          :key="index"
          :class="{ activeOpt: currentNav == index }"
          @click="
            setCurrentNav(index);
            type = item.name;
            youLikeGoods(true);
          "
          >{{ $fanyi(item.name) }}</span
        >
        <div class="underLine" :style="`left:${navLineStyle.left};`"></div>
      </div>
    </div>
    <div class="likeGoods">
      <div
        class="listOpt"
        v-for="goodsItem in goodsList"
        :key="goodsItem.sales"
        @click="$fun.toCommodityDetails(goodsItem.goodsId, goodsItem.shopType)"
      >
        <myImg class="goodsImg" lazyload :src="goodsItem.imgUrl" />
        <!-- <van-image class="goodsImg" lazy-load :src="goodsItem.imgUrl">
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image> -->
        <p class="goodsTitle">
          <span class="icon1688" v-if="goodsItem.shopType == '1688'">
            <img :src="require('@/assets/icon/icon1688.png')" alt="" /> </span
          >{{ goodsItem.titleT }}
        </p>
        <p class="goodsPrice">
          <span class="en">
            {{ $fun.RMBNumSegmentation(goodsItem.goodsPrice || 0) }}元
          </span>
          <span class="cn">
            ({{
              $fun.JPYNumSegmentation(
                $fun.roundNumber(
                  (goodsItem.goodsPrice || 0) * $store.state.exchangeRate
                )
              )
            }}円)</span
          >
        </p>
      </div>
    </div>
  </div>
  <div class="tishiFont">
    <van-loading type="spinner" size="20" />
    <span>{{ $fanyi("加载中") }}</span>
  </div>
</template>
<script>
import { getCurrentInstance } from "vue";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    return {};
  },
  data() {
    return {
      typeList: [{ name: "1688" }, { name: "Taobao" }],
      currentNav: 0, //选中选项的下标
      currentNavStyle: {
        width: 0,
        left: 0,
      },
      canLoad: true,
      timeout: false,
      type: "1688",
      goodsList: [{}, {}, {}, {}, {}, {}, {}, {}],
    };
  },
  components: {},
  computed: {
    navLineStyle() {
      let style = this.currentNavStyle;
      return {
        width: `${style.width}px`,
        left: `${style.left + (style.width - 30) / 2}px`, //30是横线的宽度,固定横线宽度的时候这么写,否则style.left就可以
      };
    },
  },
  created() {
    this.youLikeGoods(true);
  },
  mounted() {
    // 设置默认选中第一项
    this.setCurrentNav(0);
    window.addEventListener("scroll", this.scroll, true);

    // window.addEventListener("scroll", this.scroll());
    // window.onscroll = () => {
    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop;
    //   console.log(scrollTop, scrollTop >= 500);
    //   if (scrollTop >= 500) {
    //     document.getElementById("topBtn").style.display = "flex";
    //   } else if (scrollTop <= 530) {
    //     document.getElementById("topBtn").style.display = "none";
    //   }
    // };
  },
  methods: {
    scroll() {
      let dd = document.documentElement;
      if (this.$route.name != "homepage") {
        return;
      }
      if (dd.scrollTop >= dd.scrollHeight - 1900) {
        if (this.timeout) {
          return false;
        } else {
          this.timeout = true;
          this.youLikeGoods();
        }
      }
    },
    // 获取列表
    youLikeGoods(fir) {
      if (this.canLoad) {
        this.canLoad = false;
        if (this.$route.name != "homepage") {
          return;
        }
        if (fir) {
          this.goodsList = [{}, {}, {}, {}, {}, {}, {}, {}];
          this.page = 1;
        }

        this.$api
          .youLikeGoods({
            shop_type: this.type == "1688" ? "1688" : "taobao",
            page: this.page,
          })
          .then((res) => {
            this.canLoad = true;
            this.timeout = false;
            if (res.code != 0) return this.$message.error(res.data.msg);
            //接下来的操作
            if (Array.isArray(res.data)) {
              res.data = { result: [] };
            }
            if (fir) {
              this.goodsList = res.data.result;
            } else if (res.data.result.length == 0) {
              this.youLikeGoods();
            } else {
              res.data.result.forEach((element) => {
                this.goodsList.push(element);
              });
            }
            this.page++;
          });
      }
    },

    setCurrentNav(index) {
      this.currentNav = index;
      this.currentNavStyle = this.getNavStyle(index);
      let conOffetTop =
        document.getElementsByClassName("likeGoods")[0].offsetTop;
      let headOffsetHeight =
        document.getElementsByClassName("pageHead")[0].offsetHeight + 60 || 200;
      // 滚动到指定高度防止过度请求加载商品接口
      window.scrollTo(0, conOffetTop - headOffsetHeight);
    },
    getNavStyle(index) {
      // 根据导航的 index 移动下划线
      let childNodes = this.$refs.MainHeader.getElementsByTagName("span");
      //   console.log(
      //     childNodes[0],
      //     window.getComputedStyle(childNodes[0]).marginRight
      //   );
      // 根据当前导航的宽度，设置下划线的宽度
      let width = childNodes[index].clientWidth;
      // 设置下划线的默认位置
      let left = 0;
      if (index > 0) {
        // 计算左边导航的总宽度+左右外边距，设置为下划线的左移距离
        for (let i = 0; i < index; i++) {
          left += childNodes[i].clientWidth;
          left += Number(
            window
              .getComputedStyle(childNodes[0], null)
              .marginRight.slice(0, -2)
          );
          left += Number(
            window.getComputedStyle(childNodes[0], null).marginLeft.slice(0, -2)
          );
        }
      }
      return { width, left };
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.modelPage {
  padding: 0 30px;
  .styckiBox {
    position: sticky;
    top: 208px;
    background-color: #f6f6f6;
    z-index: 2;
    padding-top: 20px;
    padding-bottom: 1px;
  }
  .typeList {
    position: relative;
    min-height: 40px;
    margin-bottom: 20px;
    padding-bottom: 7px;
    span {
      display: inline-block;
      font-size: 28px;
      margin-right: 45px;
    }
    .underLine {
      position: absolute;
      bottom: 0;

      width: 30px;
      height: 5px;
      background-color: #b4272b;
      transition: all 0.2s ease-out;
    }
  }
  .typeList span.activeOpt {
    color: #b5272b;
  }
  .likeGoods {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .listOpt {
      flex: 0 0 336px;
      margin-bottom: 16px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }

      .goodsImg {
        width: 336px;
        height: 336px;
        margin-bottom: 20px;
      }
      .goodsTitle {
        width: 100%;
        font-size: 24px;
        margin-bottom: 10px;
        padding: 0 20px;
        font-weight: 400;
        color: #212121;
        line-height: 26px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .icon1688 {
          width: 61px;
          height: 23px;
          background: #ff4000;
          border-radius: 2px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          img {
            width: 42px;
          }
        }
      }
      .goodsPrice {
        font-size: 28px;
        font-weight: 600;
        color: #b4272b;
        padding: 0 20px 20px;
        line-height: 1;
        .en {
          font-size: 28px;
        }
        .cn {
          font-size: 24px;
        }
      }
    }
  }
}
.tishiFont {
  padding-bottom: 30px;
  display: flex;
  color: #999999;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  span {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    margin-left: 15px;
  }
}
</style>
