<!-- 首页滚动图组件 -->
<template>
  <van-swipe
    class="homeImgSw"
    indicator-color="white"
    :autoplay="0"
    :loop="true"
  >
    <van-swipe-item v-for="(item, index) in imageArr" :key="index">
      <a :href="item.location_url" target="_blank">
        <van-image
          class="userRegister"
          :src="item.pic_url"
          usemap="#planetmap"
          lazy-load
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="50" />
          </template>
        </van-image>
      </a>
    </van-swipe-item>
  </van-swipe>
</template>
<script>
import { Swipe, SwipeItem, ImagePreview } from "vant";
export default {
  name: "carouselMap",
  setup(props) {
    return {};
  },
  data() {
    return {
      imageArr: [],
    };
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  computed: {},
  created() {
    this.getShopBanner();
  },
  methods: {
    getShopBanner() {
      this.$api.getShopBanner().then((res) => {
        this.imageArr = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.userRegister {
  width: 100%;
  height: 490px;
  &.van-image {
    display: flex;
    //   height: 863px;
    justify-content: center;
    align-items: center;
  }
}
.homeImgSw {
}
</style>
