<template>
  <div style="background: #f6f6f6" id="homePage" @scroll="scroll">
    <!-- 头部 -->
    <headBar />
    <!-- 轮播图 -->
    <carouselMap />
    <!-- 推荐商品 -->
    <tuiJianShangPinVue />
    <!-- 网易严选 -->
    <!-- <neteaseYanXuan /> -->
    <!-- 分类商品 -->
    <fenLeiShangPinVue />
    <!-- 猜你喜欢 -->
    <guessYouLike />
    <!-- 用户未读通知 -->
    <InformBounced />
    <!-- 底部分类 -->
    <foot></foot>
  </div>
</template>
<script setup>
import headBar from "@/components/headBar/index.vue";
import tuiJianShangPinVue from "./components/tuiJianShangPin.vue";
import fenLeiShangPinVue from "./components/fenLeiShangPin.vue";
import guessYouLike from "./components/guessYouLike.vue";
import carouselMap from "./components/carouselMap.vue";
import foot from "../../components/footBar/index.vue";
import InformBounced from "./components/InformBounced.vue";
import neteaseYanXuan from "./components/neteaseYanXuan.vue";
import { watch, getCurrentInstance } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
const { proxy } = getCurrentInstance();
let router = useRouter();
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    proxy.$store.commit("getsearchInfo", "");
  },
  { immediate: true }
);
</script>
<script>
export default {
  name: "homepage",
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
</style>
