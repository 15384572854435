<!-- 通知详情显示弹窗模块 -->
<template>
  <van-dialog v-model:show="showInformDialogVisible" class="informDetail">
    <template #title>
      <div class="diaHead">
        <div>
          {{ $fanyi("通知详情") }}
        </div>
        <div class="closeBtn" @click="close">
          <div></div>
          <van-icon name="cross" />
        </div>
      </div>
    </template>
    <van-swipe
      ref="swipeRef"
      @change="beenRead"
      v-model="index"
      :show-indicators="false"
    >
      <van-swipe-item v-for="item in informList" :key="item.id">
        <div class="InformBounced ql-editor">
          <h1>{{ item.title }}</h1>
          <p class="timeBox">{{ item.created_at }}</p>
          <div class="con" v-html="item.content"></div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </van-dialog>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const showInformDialogVisible = ref(false);
const informList = ref([]);
//------------------------ pageLoad -------------------------------------
onMounted(() => {
  // 判断用户是否登录;
  if (localStorage.getItem("japan_user_token")) {
    // 获取用户未读通知
    proxy.$api.noticeUnreadNotice().then((res) => {
      if (res.code != 0) return;
      informList.value = res.data;
      if (informList.value.length > 0) {
        showInformDialogVisible.value = true;
        beenRead(0);
      }
    });
  }
});
//------------------------ methods -------------------------------------

// 对通知标记用户已读
const beenRead = (i) => {
  proxy.$api
    .noticeReadNotice({ id: informList.value[Number(i)].id })
    .then((res) => {});
};
// 关闭窗口
const close = () => {
  showInformDialogVisible.value = false;
};
</script>
<style lang="scss" scoped="scoped">
.diaHead {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  //   height: 100px;
  z-index: 1;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .closeBtn {
    position: absolute;
    right: 30px;
  }
}

.InformBounced {
  background: #fafafa;
  margin: 0px auto 0;
  border-radius: 10px;
  // width: 1120px;
  min-height: 600px;
  max-height: 60vh;
  overflow: auto;
  padding: 20px 40px 40px;
  background-color: #fafafa;
  overflow: visible;

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 10px;
    // text-align: center;
  }
  .timeBox {
    font-size: 24px;
    height: 36px;
    // text-align: center;
    margin-bottom: 20px;
    color: #999999;
  }
  .con {
    max-height: 40vh;
    overflow: auto;
    line-height: 42px;
    font-size: 28px;
  }
}
</style>
<style lang="scss">
.informDetail {
  border-radius: 6px;
  width: 650px;
  .closeBtn {
    font-size: 28px;
  }
  .van-dialog__content {
  }
  .van-dialog__footer {
    // display: none;
    button {
      .van-button__text {
        font-size: 28px;
      }
    }
  }
  overflow: auto;
  .van-dialog__header {
    padding: 20px;
    text-align: right;
  }
  .van-swipe {
    overflow: auto;
  }
}
</style>
